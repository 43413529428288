import React from 'react';
import Footer from '../components/Footer';
import Sub_head from '../components/Sub_head';
import View from '../components/Board_view';
import BoardNav from '../components/Board_nav';

import '../assets/css/sub.css';

const NoticeWrite = () => {
    return (
        <div>
            <Sub_head />
            <div className="sub_ctt wrap">
                <div className="container clearfix">
                    <div className="view_wrap">
                        <View />
                        <BoardNav />
                        <ul className="btn_box">
                            <li><a href="/notice_write" className="">수정</a></li>
                            <li><a href="/notice" className="">삭제</a></li>
                            <li><a href="/notice" className="type_01">목록</a></li>                                
                        </ul>                        
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default NoticeWrite;