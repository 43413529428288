import React from 'react';
import Footer from '../components/Footer';
import Sub_head from '../components/Sub_head'; 

const Sub1_1_view = () => {
    return (
        <div>
          <Sub_head />
          <div className="sub_ctt wrap">
            <div className="container clearfix">
              
                <div className="applicant_view">
                    <div className="tit_box">
                        <h5 className="company">주식회사 큐라티스</h5>
                        <h4 className="tit">코로나 19 백신 개발을 위한 임상시험 대상자 모집</h4>
                        <p className="txt">건강한 성인을 대상으로 SARS-CoV-2 감영 예방을 위한 백신(QTP104)의 안정성, 반응원성 <br />및 면역원성을 평가하기 위한 용량증량, 다기관, 공개, 제1상 임상시험</p>
                    </div>
                    <div className="info_box">
                        <dl className="info01 info">
                            <dt><span className="num">01</span>임상시험 목적</dt>
                            <dd>
                                <ul className="list">
                                    <li>건강한 성인을 대상으로 SARS-CoV-2 감염 예방을 위한 QTP104 백신의 안정성, 반응원성 및 면역원성을 평가하기 위해 실시합니다</li>
                                </ul>
                            </dd>
                        </dl>
                        <dl className="info02 info">
                            <dt><span className="num">02</span>자격요건</dt>
                            <dd>
                                <ul className="list">
                                    <li>만 19세 이상 55세 이하의 건강한 성인 남성 또는 여성</li>
                                    <li>자발적으로 임상시험 참여를 결정하고 주의사항 등을 준수하기로 서면동의 가능하신 분</li>
                                </ul>
                            </dd>
                        </dl>
                        <dl className="info03 info">
                            <dt><span className="num">03</span>임상시험 방법</dt>
                            <dd>
                                <ul className="list">								
                                    <li>순차적으로 배정되어 임상시험용 의약품을 총 2회, 근육 주사로 투여받게 됩니다.</li>
                                    <li>임상시험 기간동안 흉부 X-ray 및 신체검사, 실험실적 검사, 면역원성 검사 등을 진행합니다.</li>
                                    <li>약 12개월의 임상시험 기간 동안 8회 병원 방문을 실시합니다.(단, 전화모니터링 1회 별도)</li>
                                </ul>
                            </dd>
                        </dl>
                        <dl className="info04 info">
                            <dt><span className="num">04</span>참여 기관 및 모집인원</dt>
                            <dd>
                                <ul className="list">
                                    <li>2개의 임상시험 실시기관(연세대학교의과대학 신촌세브란스병원/강남세브란스병원)에서 36명의 대상자를 모집합니다.</li>
                                </ul>
                            </dd>
                        </dl>
                        <dl className="info05 info">
                            <dt><span className="num">05</span>시험대상자에게 제공되는 사항</dt>
                            <dd>
                                <ul className="list">
                                    <li>임상시험 기간 동안 전문 의료진의 진료 및 검진을 받으실 수 있습니다.</li>
                                    <li>임상시험 관련 섬사 및 임상시험용의약품을 지원받게 되며, 시험대상자에게 임상시험 참여기간 동안 모든 방문마다 소정의 교통비가 제공됩니다.</li>
                                </ul>
                            </dd>
                        </dl>
                        <dl className="info06 info">
                            <dt><span className="num">06</span>예측 가능한 이상반응</dt>
                            <dd>
                                <ul className="list">
                                    <li>임상시험용의약품 접종 후 주사부위의 약간의 통증, 발적, 부종, 가려움증 등이 발생할 수 있습니다.</li>
                                    <li>상기 부작용 외 예상하지 못한 부작용도 발생할 수 있습니다.</li>
                                </ul>
                            </dd>
                        </dl>
                        <dl className="info07 info">
                            <dt><span className="num">07</span>문의</dt>
                            <dd>
                                <ul className="list">
                                    <li>문의가 있으면 아래 전화번호로 연락해 주십시오</li>
                                    <li>실시기관 및 시험 책임자: 연세대학교의과대학 신촌세브란스 병원 감염내과 홍길동 교수</li>
                                </ul>
                            </dd>
                        </dl>
                        <dl className="map_wrapinfo08 info">
                            <dt><span className="num">08</span>오시는길</dt>
                            <dd>
                                지도 위치 영역
                            </dd>
                        </dl>
                    </div>
                    <div className="tac"><a href="/sub1_1_ask" className="ask_go">지원하기</a></div>
                </div>

            </div>
          </div>
          <Footer />
        </div>
    )
}

export default Sub1_1_view;
