import React from 'react';
import Footer from '../components/Footer';
import Sub_head from '../components/Sub_head';
import View from '../components/Board_view';
import Comment from '../components/Board_comment';

import '../assets/css/sub.css';

const QnaView = () => {
    return (
        <div>
            <Sub_head />
            <div className="sub_ctt wrap">
                <div className="container clearfix">
                    <div className="view_wrap">
                        <View />
                        <ul className="btn_box">
                            <li><a href="/qna_write" className="">수정</a></li>
                            <li><a href="/qna" className="">삭제</a></li>
                            <li><a href="/qna" className="type_01">목록</a></li>                                
                        </ul>
                        <Comment />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default QnaView;