import React from 'react';
import Footer from '../components/Footer';
import Sub_head from '../components/Sub_head';

import '../assets/css/sub.css';

const Hospital_ask = () => {
    return (
        <div>
            <Sub_head />
            <div className="sub_ctt wrap">
                <div className="container clearfix">
                    
                    <form className="hospital_ask">
                        <div className="form_box">
                            <dl className="half_size">
                                <dt>회사명</dt>
                                <dd><input type="text" placeholder="ex) 손정형외과" /></dd>
                            </dl>
                            <dl className="name_position half_size">
                                <dt>성함/직책</dt>
                                <dd><input type="text" placeholder="ex) 홍길동/원장" /></dd>
                            </dl>
                            <dl className="clearfix">
                                <dt>연락처</dt>
                                <dd><input type="text" placeholder="담당자 연락처를 입력해주세요" /></dd>
                            </dl>
                            <dl>
                                <dt>이메일</dt>
                                <dd><input type="text" placeholder="이메일 주소를 입력해주세요" /></dd>
                            </dl>
                        </div>
                        <div className="tac"><button>신청하기</button></div>
                    </form>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Hospital_ask;