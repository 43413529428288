import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Login from './components/Login';
import Join_form from './components/Join_form';
import Lost from './components/Lost';

import Main from './page/Main';
import Sub1_1 from './page/Sub1_1';
import Sub1_1_view from './page/Sub1_1_view';
import Sub1_1_ask from './page/Sub1_1_ask';

import Hospital_ask from './page/Hospital_ask';

import Notice from './page/Notice';
import NoticeWrite from './page/Notice_write';
import NoticeView from './page/Notice_view';

import Qna from './page/Qna';
import QnaWrite from './page/Qna_write';
import QnaView from './page/Qna_view';

import Intro from './page/Intro';
import Privacy from './page/Privacy';
import Provision from './page/Provision';

import './assets/css/common.css';
import './assets/css/responsive.css';

const App = () => {
    return (   
      <div> 
      <Router>
        <Routes>
          <Route path='/' element={<Main />} />
          <Route path='/sub1' element={<Sub1_1 />} />
          <Route path='/sub1_1_view' element={<Sub1_1_view />} />
          <Route path='/sub1_1_ask' element={<Sub1_1_ask />} />

          <Route path='/hospital_ask' element={<Hospital_ask />} />

          <Route path='/notice' element={<Notice />} />
          <Route path='/notice_write' element={<NoticeWrite />} />
          <Route path='/notice_view' element={<NoticeView />} />

          <Route path='/qna' element={<Qna />} />
          <Route path='/qna_write' element={<QnaWrite />} />
          <Route path='/qna_view' element={<QnaView />} />

          <Route path='/intro' element={<Intro />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/provision' element={<Provision />} />

          <Route path='/login' element={<Login />} />
          <Route path='/join_form' element={<Join_form />} />
          <Route path='/lost' element={<Lost />} />
        </Routes>
      </Router>
      </div>        
    )
}

export default App;
