import React from 'react';
import Footer from '../components/Footer';
import Sub_head from '../components/Sub_head';

import '../assets/css/sub.css';

const Provision = () => {
    return (
        <div>
            <Sub_head />
            <div className="sub_ctt wrap">
                <div className="container clearfix">
                    <div className="term">
                        <dl className="inner">

                            <dt className="big">제1장 총 칙</dt>
                            <dd></dd>

                            <dt>제1조 (목적) 본 약관은 오케이닥터가 제공하는 인터넷관련 서비스(이하 "서비스"라 합니다)를 이용함에 있어 그 이용조건 및 절차와 관련하여 오케이닥터와 이용자의 권리, 의무 및 책임 등 필요한 사항을 규정함을 목적으로 합니다.</dt>
                            <dd></dd>

                            <dt>제2조 (약관의 효력과 변경)</dt>
                            <dd>
                                <p>① 본 약관은 서비스를 이용하고자 하는 모든 이용자에 대하여 그 효력을 발생합니다.</p>
                                <p>② 본 약관의 내용은 서비스의 일부 화면 또는 기타 방법등에 의하여 이를 공지하거나 그 내용을 회원에게 통지함으로써 효력이 발생됩니다.</p>
                                <p>③ 오케이닥터는 운영 또는 사업상 중요한 사유가 있을 경우 본 약관을 임의로 변경할 수 있으며, 변경된 약관은 제2항과 같은 방법으로 공지 또는 통지함으로써 그 효력을 발생합니다.</p>
                                <p>④ 회원은 변경된 약관에 동의하지 않을 경우 회원탈퇴를 요청할 수 있으며, 변경된 약관의 효력발생일 이후에도 서비스를 계속 사용할 경우 약관의 변경사항에 동의한 것으로 간주됩니다.</p>
                            </dd>

                            <dt>제3조 (약관 외 준칙) 본 약관에 명시되지 않은 사항은 공공기관의 개인정보보호에 관한 법률, 전기통신기본법, 전기통신사업법, 정보통신윤리위원회심의규정, 정보통신윤리강령, 정보통신망이용촉진 및정보보호등에 관한 법률, 전자서명법 및 기타 관련 법령, 공단이 별도로 정한 지침 등의 규정에 따릅니다.</dt>
                            <dd></dd>
                            
                            <dt>제4조 (용어의 정의) 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</dt>
                            <dd>
                               <p>① "이용자"라 함은 오케이닥터 인터넷에 접속하여 이 약관에 따라 서비스를 받는 자를 말합니다.</p>
                               <p>② "회원"이라 함은 본 약관에 동의하고, 오케이닥터 인터넷 홈페이지에 개인정보를 제공하여 ID(고유번호)와 비밀번호 Password(비밀번호)를 발급 받으신 분을 말합니다.</p>
                               <p>③ "ID(고유번호)"라 함은 회원식별과 회원의 서비스 이용을 위하여 회원임을 확인하고 회원자신의 비밀보호를 위해 회원 자신이 선정하고 오케이닥터가 승인하는 영문자와 숫자의 조합을 말합니다.</p>
                               <p>④ "Password(비밀번호)"라 함은 부여받은 회원 ID(고유번호)와 일치된 회원임을 확인하고 자신의 비밀보호를 위해 회원자신이 설정한 문자와 숫자의 조합을 말합니다.</p>
                               <p>⑤ "운영자"라 함은 서비스의 전체 또는 일부의 관리·운영을 위하여 오케이닥터에서 지정한 자를 말합니다.</p>
                               <p>⑥ "해지"라 함은 오케이닥터 또는 회원이 이용계약을 종료시키는 것을 말합니다.</p>
                            </dd>

                            <dt>제5조 (서비스의 내용) 오케이닥터는 회원에게 오케이닥터에서 자체 개발하는 서비스 및 별도로 정하는 각종 서비스 등을 제공합니다. 단, 오케이닥터의 사정상 각 서비스별로 제공일정 및 제공방법을 변경하거나 지연제공 또는 그 제공을 하지 아니할 수 있습니다.</dt>
                            <dd></dd>

                            <dt>제6조 (서비스 제공범위)</dt>
                            <dd>
                                <p>① 이용자에게 제공되는 인터넷 서비스의 제공범위는 다음 각호와 같으며, 필요한 경우 이를 추가하거나 제한 할 수 있습니다.</p>
                                <p>1. 회원의 경우 : 오케이닥터에 대한 생동성, 임상시험 지원자 모집 지원 신청 내용(개인 신상 정보)을 조회, 열람 및 출력 등을 할 수 가 있으며, 회사문의사항 등을 통하여 기타 상담 및 각종 민원을 신청 등을 하실 수 있습니다.</p>
                                <p>2. 비회원의 경우 : 공공기관의 개인정보보호에 관한 법률 제10조의 규정에 위배되지 아니하는 오케이닥터에 대한 생동성, 임상시험 모집공고를 볼수 가 있습니다.</p>
                            </dd>

                            <dt className="big">제2장 서비스 이용계약</dt>
                            <dd></dd>

                            <dt>제7조 (이용계약의 성립)</dt>
                            <dd>
                                <p>① 이용신청시 "위의 이용약관에 동의하십니까?"라는 물음에 회원이 "동의합니다" 단추를 누르면 이 약관에 동의하는 것으로 간주됩니다.</p>
                                <p>② 이용계약은 제8조에 규정한 회원의 이용신청에 대하여 오케이닥터가 승낙함으로써 성립합니다.</p>
                            </dd>

                            <dt>제8조 (이용신청) 이용신청은 온라인 또는 오케이닥터가 별도로 정한 양식 및 방법에 의하여 다음 사항을 기록하여 신청합니다.</dt>
                            <dd><p>① 성명, 생년월일, 이메일, 주소, 이동전화번호, 이메일, 기타 오케이닥터가 필요하다고 인정하는 사항</p></dd>
                            
                            <dt>제9조 (이용신청의 승낙)</dt>
                            <dd>
                                <p>① 오케이닥터는 회원이 제8조에서 정한 사항을 정확히 기재하여 이용 신청을 하였을 때 다음 제2항, 제3항의 경우를 예외로 하여 승낙할 수 있습니다. 또한, 기재사항의 항목에 따라 서비스 이용의 일부가 제한될 수 있습니다.</p>
                                <p>② 오케이닥터는 다음의 경우에는 그 사유가 해소될 때까지 이용신청에 대한 승낙을 유보할 수 있습니다.</p>
                                <p>1. 서비스 이용설비에 여유가 없는 경우</p>
                                <p>2. 기술상 서비스 제공이 불가능한 경우</p>
                                <p>3. 기타 사유로 이용승낙이 곤란한 경우</p>
                                <p>③ 오케이닥터는 다음 각호의 1에 해당하는 이용신청에 대하여 이를 승낙하지 아니할 수 있습니다.</p>
                                <p>1. 본인의 실명으로 신청하지 않은 경우</p>
                                <p>2. 다른 사람의 명의를 사용하여 신청한 경우</p>
                                <p>3. 이용 신청시 필요내용을 허위로 기재하여 신청한 경우</p>
                                <p>4. 사회의 안녕·질서 또는 미풍양속을 저해할 목적으로 신청한 경우</p>
                                <p>5. 오케이닥터의 고객서비스에 악영향을 줄 수 있다고 판단되는 경우</p>
                                <p>6. 기타 오케이닥터가 정한 이용신청요건이 만족되지 않았을 경우</p>
                                <p>④ 오케이닥터 홈페이지 내의 모든 서비스는 특별한 사정이 없는 한 회원가입 신청 후 24시간이내에 이용할 수 있도록 합니다.</p>
                            </dd>

                            <dt>제10조(계약사항의 변경) 회원은 이용신청시 기재한 사항이 변경되었을 경우 온라인 또는 오케이닥터가 별도로 정한 양식 및 방법에 의하여 수정하여야 하고, 미변경으로 인하여 발생되는 문제에 대한 책임은 회원에게 있습니다.</dt>
                            <dd></dd>

                            <dt className="big">제3장 계약당사자의 의무</dt>
                            <dd></dd>

                            <dt>제11조(오케이닥터의 의무)</dt>
                            <dd>
                                <p>① 오케이닥터는 법령과 본 약관이 금지하거나 미풍약속에 반하는 행위를 하지 않으며, 계속적·안정적으로 서비스를 제공하기 위해 노력할 의무가 있습니다.</p>
                                <p>② 오케이닥터는 서비스 제공과 관련해서 알고 있는 회원의 신상정보를 본인의 승낙 없이 제3자에게 누설·배포하지 않습니다. 단, 법률의 규정에 따른 적법한 절차에 의한 경우에는 그러하지 않습니다.</p>
                                <p>③ 제2항의 범위 내에서, 오케이닥터는 업무와 관련하여 회원전체 또는 일부의 개인정보에 관한 통계자료를 작성하여 이를 사용할 수 있습니다.</p>
                                <p>④ 오케이닥터는 회원이 안정적으로 서비스를 이용할 수 있도록 회원의 개인정보(신용정보포함)보호를 위한 보안시스템을 갖추어야 합니다.</p>
                                <p>⑤ 오케이닥터는 회원의 귀책사유로 인한 고객서비스 이용장애에 대하여 책임을 지지 않습니다.</p>
                            </dd>

                            <dt>제12조(회원의 의무)</dt>
                            <dd>
                                <p>① 회원은 서비스 이용과 관련하여 다음 각호의 1에 해당되는 행위를 하여서는 안됩니다.</p>
                                <p>1. 다른 회원의 ID(고유번호)와 Password(비밀번호) 등을 도용하거나 자신의 ID(고유번호)와 Password(비밀번호) 등을 제3자에게 이용하게 하는 행위</p>
                                <p>2. 서비스를 이용하여 얻은 정보를 회원의 개인적인 이용 외에 복사, 가공, 번역, 2차적 저작 등을 통하여 복제, 공연, 방송, 전시, 배포, 출판 등에 사용하거나 제3자에게 제공하는 행위</p>
                                <p>3. 타인의 명예를 손상시키거나 불이익을 주는 행위</p>
                                <p>4. 오케이닥터의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 행위</p>
                                <p>5. 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형, 동영상, 음성 등을 타인에게 유포하는 행위</p>
                                <p>6. 범죄와 결부된다고 객관적으로 인정되는 행위</p>
                                <p>7. 서비스와 관련된 설비의 오동작이나 정보 등의 파괴 및 혼란을 유발시키는 컴퓨터 바이러스 감염자료를 등록 또는 유포하는 행위</p>
                                <p>8. 서비스의 안정적 운영을 방해할 수 있는 정보를 전송하거나 수신자의 의사에 반하여 광고성 정보를 전송하는 행위</p>
                                <p>9. 정보통신윤리위원회,소비자보호단체등 공신력있는 기관으로부터 시정요구를 받는행위</p>
                                <p>10. 선거관리위원회의 중지, 경고 또는 시정명령을 받는 선거법 위반 행위</p>
                                <p>11. 기타 관계 법령에 위배되는 행위</p>
                                <p>① 회원은 등록정보에 변경사항이 발생할 경우 즉시 갱신하셔야 합니다.</p>
                                <p>② 회원은 이 약관에서 규정하는 사항과 서비스 이용안내 또는 주의사항 등 오케이닥터가 공지 또는 통지하는 사항을 준수하여야 합니다.</p>
                                <p>③ 회원은 내용별로 공단이 서비스 공지사항에 게시하거나 별도로 공지한 이용 제한사항을 준수하여야 하며, 기타 오케이닥터의 업무에 방해되는 행위를 하여서는 안됩니다.</p>
                                <p>④ 회원은 오케이닥터의 사전승낙 없이는 서비스를 이용하여 영업활동을 할 수 없으며, 그 영업 활동의 결과와 회원이 약관을 위반한 영업 활동을 이용하여 발생한 결과에 대하여 오케이닥터는 책임을 지지 않습니다. 회원은 이와 같은 영업활동과 관련하여 오케이닥터에 대하여 손해배상 의무를 집니다.</p>
                                <p>⑤ 회원은 서비스의 이용권한, 기타 이용 계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.</p>
                                <p>⑥ 회원은 오케이닥터의 사전승낙 없이는 서비스의 전부 또는 일부 내용 및 기능을 전용할 수 없습니다.</p>
                            </dd>

                            <dt className="big">제4장 서비스 이용</dt>
                            <dd></dd>

                            <dt>제13조(회원 ID(고유번호)와 Password(비밀번호)관리에 대한 회원의 의무와 책임)</dt>
                            <dd>
                                <p>① ID(고유번호)와 Password(비밀번호)에 관한 모든 관리책임은 회원에게 있습니다. 회원에게 부여된 ID(고유번호)와 Password(비밀번호)의 관리소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 회원에게 있습니다.</p>
                                <p>② 자신의 ID(고유번호)가 타인에 의해 무단 이용되는 경우 회원은 반드시 오케이닥터에 그 사실을 통보해야 합니다.</p>
                                <p>③ 회원의 ID(고유번호)는 오케이닥터의 사전 동의 없이 변경할 수 없습니다.</p>
                            </dd>
                            
                            <dt>제13조2 (공인인증서의 이용) 오케이닥터는 회원이 오케이닥터에서 제공하는 일부 서비스(개인정보 관련)를 이용하는 경우 회원에게 서비스의 안정성 및 보안성 확보를 위하여 필요한 경우 이용자에게 추가 개인정보를 요구할 수 있습니다.</dt>
                            <dd></dd>

                            <dt>제14조(전자메일에 대한 회원의 의무와 책임)</dt>
                            <dd>
                                <p>① 오케이닥터는 회원의 전자메일내용을 편집하거나 감시하지 않으며 메일내용에 대한 책임은 각 회원에게 있습니다.</p>
                                <p>② 회원은 오케이닥터의 전자메일을 통하여 음란물이나 불온한 내용, 정크메일(Junk Mail), 스팸메일(Spam Mail)등 타인에게 피해를 주거나 미풍양속을 해치는 메일을 보내서는 안됩니다.</p>
                                <p>③ 제2항을 위반하여 발생되는 모든 책임은 회원에게 있으며, 이 경우 회원의 ID(고유번호)와 Password(비밀번호)를 수사기관에 제공할 수 있습니다.</p>
                            </dd>

                            <dt>제15조(정보의 제공)</dt>
                            <dd><p>① 오케이닥터는 회원이 서비스 이용 도중 필요가 있다고 인정되는 다양한 정보에 대해서 전자우편이나 서신우편 등의 방법으로 회원에게 제공할 수 있습니다. 오케이닥터는 불특정 다수 회원에게 공통의 정보를 통지하고자 하는 경우, 1주일 이상 게시판에 게시함으로써 개별통지에 갈음할 수 있습니다.</p></dd>

                            <dt>제16조(회원의 게시물)</dt>
                            <dd>
                                <p>① 오케이닥터는 회원이 게시하거나 등록하는 서비스내의 내용물(회원간 전달포함)이 다음 각호의 1에 해당한다고 판단되는 경우에 사전통지 없이 삭제할 수 있습니다.</p>
                                <p>1. 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우</p>
                                <p>2. 공공질서 및 미풍양속에 위반되는 내용인 경우</p>
                                <p>3. 범죄적 행위에 결부된다고 인정되는 내용일 경우</p>
                                <p>4. 오케이닥터의 저작권, 제3자의 저작권 등 권리를 침해하는 내용인 경우</p>
                                <p>5. 제2항 소정의 세부 이용지침을 통하여 오케이닥터에서 규정한 게시기간을 초과한 경우</p>
                                <p>6. 기타 관계 법령 및 오케이닥터의 세부 지침 등에 위반된다고 판단되는 경우</p>
                                <p>① 오케이닥터는 게시물에 관련된 세부 이용지침을 별도로 정하여 시행할 수 있으며 회원은 그 지침에 따라 각종 게시물(회원간 전달 포함)의 등록, 삭제 등을 하여야 합니다.</p>
                            </dd>

                            <dt>제17조(게시물의 저작권) 서비스에 게재된 모든 자료(회원간 전달 포함)에 대한 권리는 다음 각호와 같습니다.</dt>
                            <dd>
                                <p>1. 게시물에 대한 권리와 책임은 게시자에게 있으며 오케이닥터는 게시자의 동의 없이는 이를 서비스내 게재 이외에 영리적 목적으로 사용할 수 없습니다. 단, 비영리적인 경우에는 그러하지 아니하며 또한 오케이닥터는 서비스내의 게재권을 갖습니다.</p>
                                <p>2. 회원은 서비스를 이용하여 얻은 정보를 가공, 판매하는 행위 등 서비스에 게재된 자료를 상업적으로 사용할 수 없습니다.</p>
                            </dd>

                            <dt>제18조(서비스 이용시간)</dt>
                            <dd>
                                <p>① 서비스의 이용은 공단의 업무상 또는 기술상 특별한 지장이 없는 한 연중 무휴, 1일 24시간 가능함을 원칙으로 합니다. 다만, 오케이닥터가 정기점검 등의 서비스 점검 및 조치 등을 위하여 필요로 하는 시간은 그러하지 않습니다.</p>
                                <p>② 오케이닥터는 서비스를 일정범위로 분할하여 각 범위별로 이용가능 시간을 별도로 정할 수 있습니다. 이 경우 사전에 공지를 통해 그 내용을 알립니다.</p>
                            </dd>

                            <dt>제19조(서비스 이용요금) 공단이 제공하는 모든 서비스는 무료로 제공함을 원칙으로 합니다.</dt>
                            <dd></dd>

                            <dt>제20조(서비스 이용상의 책임) 회원은 오케이닥터에서 공식적으로 인정한 경우를 제외하고는 서비스를 이용하여 상품을 판매하거나 고객을 유인하는 등 영업활동을 할 수 없으며, 특히 해킹, 광고를 통한 수익, 음란사이트를 통한 상업적 행위, 상용S/W 불법배포 등을 할 수 없습니다. 이를 어기고 발생한 영업활동의 결과 및 손실, 관계기관에 의한 수사·처벌 등 법적조치 등에 관해서는 오케이닥터는 책임을 지지 않습니다.</dt>
                            <dd></dd>

                            <dt>제21조(서비스 제공의 중지)</dt>
                            <dd>
                                <p>① 오케이닥터는 다음 각호의1에 해당하는 경우 서비스 제공을 중지할 수 있습니다.</p>
                                <p>1. 서비스용 장비의 점검 및 보수 등 공사로 인한 부득이한 경우</p>
                                <p>2. 오케이닥터가 서비스하는 사항에 대하여 지대한 영향을 미칠 경우</p>
                                <p>3. 기타 불가항력적 사유가 있는 경우</p>
                                <p>② 오케이닥터는 국가비상사태, 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 때에는 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.</p>
                            </dd>

                            <dt className="big">제5장 계약해지 및 이용제한</dt>
                            <dd></dd>

                            <dt>제22조(계약해지 및 이용제한)</dt>                            
                            <dd>
                                <p>① 회원이 서비스 이용계약을 해지하고자 할 경우 본인이 온라인 또는 오케이닥터가 정한 별도의 방법을 통해 공단에 해지신청을 하여야 합니다.</p>
                                <p>② 오케이닥터는 회원이 다음 각호의 1에 해당하는 행위를 하였을 경우 사전통지 없이 이용계약을 해지하거나 서비스 이용을 중지하고 관계당국에 고발할 수 있습니다.</p>
                                <p>1. 타인의 개인정보, 서비스 ID(고유번호) 및 Password(비밀번호)를 도용한 경우</p>
                                <p>2. 서비스 운영을 고의로 방해한 경우</p>
                                <p>3. 가입한 이름이 실명이 아닌 경우</p>
                                <p>4. 동일 사용자가 다른 ID(고유번호)로 이중등록을 한 경우</p>
                                <p>5. 공공질서 및 미풍양속에 저해되는 내용을 고의로 유포시킨 경우</p>
                                <p>6. 회원이 국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는 실행하는 경우</p>
                                <p>7. 타인에 대하여 비방하거나 명예를 손상시키거나 불이익을 주는 행위를 한 경우</p>
                                <p>8. 서비스의 안정적 운영을 방해할 목적으로 정보를 전송하거나 광고성 정보를 전송하는 경우</p>
                                <p>9. 통신설비의 오동작이나 정보 등의 파괴를 유발시키는 컴퓨터 바이러스 프로그램등을 유포하는 경우</p>
                                <p>10. 오케이닥터, 다른 회원 또는 제3자의 지적재산권을 침해하는 경우</p>
                                <p>11. 정보통신윤리위원회 등 외부기관의 시정 요구가 있거나 선거관리위원회의 중지, 경고 또는 시정명령을 받는 선거법 위반행위가 있는 경우</p>
                                <p>12. 오케이닥터의 서비스를 이용하여 얻은 정보를 공단의 사전 승낙 없이 복제 또는 유통시키거나 상업적으로 이용하는 경우</p>
                                <p>13. 게시판 등에 음란물을 게재하거나 음란사이트를 연결(링크)하는 경우</p>
                                <p>14. 회원이 제공한 정보 및 갱신한 정보가 부정확할 경우</p>
                                <p>15. 이용약관 기타 오케이닥터가 정한 이용조건에 위반한 경우</p>
                                <p>① 오케이닥터는 회원이 이용계약을 체결하여 ID(고유번호) 및 Password(비밀번호)를 부여받은 후에도 회원의 조건에 따라 서비스 이용을 제한할 수 있습니다.</p>
                                <p>② 회원은 제2항 및 제3항에 의한 오케이닥터의 조치에 대하여 공단이 정한 절차에 따라 이의신청을 할 수 있습니다.</p>
                                <p>③ 제4항의 이의가 정당하다고 오케이닥터가 인정하는 경우, 오케이닥터는 즉시 서비스의 이용을 재개하여야 합니다.</p>
                            </dd>

                            <dt className="big">제6장 손해배상 등</dt>
                            <dd></dd>

                            <dt>제23조(손해배상)</dt>
                            <dd>
                                <p>① 오케이닥터는 서비스 이용과 관련하여 회원에게 발생한 어떠한 손해에 대하여도 배상할 책임을 지지 않습니다.</p>
                                <p>② 회원이 서비스를 이용함에 있어 행한 불법행위로 인하여 오케이닥터가 당해 회원 이외의 제3자로부터 손해배상청구, 소송을 비롯한 각종의 이의제기를 받는 경우 당해 회원은 오케이닥터의 면책을 위하여 노력하여야 하며, 만일 오케이닥터가 면책되지 못한 경우는 당해 회원은 그로 인하여 오케이닥터에 발생한 모든 손해를 배상하여야 합니다.</p>
                            </dd>

                            <dt>제24조(면책사항)</dt>
                            <dd>
                                <p>① 오케이닥터는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.</p>
                                <p>② 오케이닥터는 회원의 귀책사유로 인한 서비스의 이용장애에 대하여 책임을 지지 않습니다.</p>
                                <p>③ 오케이닥터는 회원이 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지않으며 그 밖에 서비스를 통하여 얻은 자료로 인한 손해 등에 대하여도 책임을 지지 않습니다.</p>
                                <p>④ 오케이닥터는 회원이 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 대하여는 책임을 지지 않습니다.</p>
                            </dd>

                            <dt>제25조(관할법원)</dt>
                            <dd>
                                <p>① 서비스 이용과 관련하여 공단과 회원사이에 분쟁이 발생한 경우, 쌍방간에 분쟁의 해결을 위해 성실히 협의한 후가 아니면 제소할 수 없습니다.</p>
                                <p>② 제1항의 협의에서도 분쟁이 해결되지 않을 경우 관할법원은 오케이닥터 소재지를 관할하는 법원을 전속 관할법원으로 합니다.</p>
                            </dd>

                            <dt>부 칙</dt>
                            <dd><p>제1조(시행일) 본 약관은 2022년 01월 01일부터 시행한다.</p></dd>
                        </dl>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Provision;