import React from 'react';
import Footer from '../components/Footer';
import Sub_head from '../components/Sub_head';
import Write from '../components/Board_write';

import '../assets/css/sub.css';

const NoticeWrite = () => {
    return (
        <div>
            <Sub_head />
            <div className="sub_ctt wrap">
                <div className="container clearfix">
                    <div className="write_form">
                        <form>
                            <Write />
                            <ul className="btn_box">
                                <li><a href="/notice" className="">취소</a></li>
                                <li><a href="/notice_view" className="type_01">작성완료</a></li>                                
                            </ul>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default NoticeWrite;