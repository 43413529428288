import React from 'react';

import '../assets/css/sub.css';

const BoardWrite = () => {
    return (
        <div>            
            <ul className="form">
                <li className="text_type">
                    <label>제목</label>
                    <input type="text" placeholder="제목"></input>
                </li>
                <li className="text_type">
                    <label>내용</label>
                    <textarea placeholder="내용"></textarea>
                </li>
                <li className="file_type">
                    <label>링크#1</label>
                    <div className="input_box"><i class="fas fa-link"></i><input type="text"></input></div>
                </li>
                <li className="file_type">
                    <label>링크#2</label>
                    <div className="input_box"><i class="fas fa-link"></i><input type="text"></input></div>
                </li>
                <li className="file_type">
                    <label>파일#1</label>
                    <div className="input_box"><i class="fas fa-download"></i><input type="file"></input></div>
                </li>
                <li className="file_type">
                    <label>파일#2</label>
                    <div className="input_box"><i class="fas fa-download"></i><input type="file"></input></div>
                </li>
            </ul>
                    
        </div>
    )
}

export default BoardWrite;