import React from 'react';
import Footer from '../components/Footer';
import Sub_head from '../components/Sub_head';

import '../assets/css/sub.css';

const Lost = () => {
    return (
        <div>
            <Sub_head />
            <div className="sub_ctt wrap">
                <div className="container clearfix">

                    <div className="lost_wrap login_wrap">
                        <form className="input_box box"><p className="ps">회원가입 시 등록하신 이메일 주소를 입력해 주세요. <br/>해당 이메일로 아이디와 비밀번호 정보를 보내드립니다.</p>
                            <dl>
                                <dt>이메일</dt>
                                <dd><i class="fas fa-envelope"></i><input type="text" placeholder="E-mail" /></dd>
                            </dl>
                            <button>확인</button>
                        </form>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Lost;