import React from 'react';
import Footer from '../components/Footer';
import Sub_head from '../components/Sub_head';

import '../assets/css/sub.css';

const Sub1_1_ask = () => {
    return (
        <div className="nav_white">
          <Sub_head />
          <div className="sub_ctt wrap">
            <div className="container clearfix">
                
                <form className="applicant_ask">
                  <dl className="tit_box">
                    <dt>임상시험명 :</dt>
                    <dd>코로나 19 백신 개발을 위한 임상시험 대상자 모집</dd>
                  </dl>
                  <p className="date">임상시험 신청일 : <strong>2021-12-21</strong></p>
                  <div className="ask_form">
                    <p className="ps"><i>*</i> 은 필수입력 항목입니다.</p>
                    <dl>
                      <dt className="important">이름</dt>
                      <dd><input type="text" placeholder /></dd>
                      <dt className="important">생년월일</dt>
                      <dd>
                        <select className="year">
                          <option>년도</option>
                          <option>2022</option>
                          <option>2021</option>
                          <option>2020</option>
                          <option>2019</option>
                        </select> 
                        <select className="month">
                          <option>월</option>
                          <option>12</option>
                          <option>11</option>
                          <option>10</option>
                          <option>9</option>
                        </select> 
                        <select className="day">
                          <option>일</option>
                          <option>31</option>
                          <option>30</option>
                          <option>29</option>
                          <option>28</option>
                        </select>                       
                      </dd>
                      <dt className="important">연락처</dt>
                      <dd class="btn_type"><input type="text" placeholder="“-” 제외한 숫자만 입력하세요." /><a href="" target="_blank">본인인증</a></dd>
                      <dt className="important">연락처(집)</dt>
                      <dd><input type="text" placeholder="“-” 제외한 숫자만 입력하세요." /></dd>
                      <dt className="important">성별</dt>
                      <dd class="check_type">
                        <span>
                          <input type="checkbox" /> 남자
                        </span>
                        <span>
                          <input type="checkbox" /> 여자
                        </span>
                      </dd>
                      <dt className="important">주소</dt>
                      <dd><input type="text" /></dd>
                      <dt className="important">이메일</dt>
                      <dd className="mail_type">
                        <input type="text" /><i>@</i>
                        <input type="text" />
                        <select>
                          <option>직접입력</option>
                          <option>naver.com</option>
                        </select>
                      </dd>
                      <dt className="">최근 참여경험 확인</dt>
                      <dd class="check_type"><input type="checkbox" /> 6개월 이내에 다른 임상시험에 참여한 경험이 있습니다.</dd>
                      <dt className="">헌혈여부</dt>
                      <dd class="check_type">
                        <span>
                          <input type="checkbox" /> No
                        </span>
                        <span>
                          <input type="checkbox" /> Yes
                        </span>
                      </dd>
                      <dt className="">약물복용</dt>
                      <dd class="check_type">
                        <span>
                          <input type="checkbox" /> No
                        </span>
                        <span>
                          <input type="checkbox" /> Yes
                        </span>
                        <input type="text" placeholder="'Yes 체크 시 입력해주세요.'" />
                      </dd>
                      <dt className="">병력</dt>
                      <dd className="check_type">
                        <span>
                          <input type="checkbox" /> No
                        </span>
                        <span>
                          <input type="checkbox" /> Yes
                        </span>
                        <input type="text" placeholder="'Yes 체크 시 입력해주세요.'" />
                      </dd>
                      <dt className="">흡연</dt>
                      <dd class="check_type">
                        <span>
                          <input type="checkbox" /> No
                        </span>
                        <span>
                          <input type="checkbox" /> Yes
                        </span>
                      </dd>
                      <dt className="">참고사항</dt>
                      <dd><textarea></textarea></dd>
                      <dt className="">&nbsp;</dt>
                      <dd className="agree_box">
                        <div className="agree_info">
                        1. 개인정보의 처리 목적<br /><br />

                        &lt;오케이닥터&gt;(‘www.okdr.com’이하 ‘오케이닥터’) 은(는) 다음의 목적을 위하여
                        개인정보를 처리하고 있으며, 다음의 목적 이외의 용도로는 이용하지 않습니다.
                        고객 가입의사 확인, 고객에 대한 서비스 제공에 따른 본인 식별.인증, 회원자격 유지.
                        관리, 물품 또는 서비스 공급에 따른 금액 결제, 물품 또는 서비스의 공급.배송 등<br /><br />

                        2. 개인정보의 처리 목적<br /><br />

                        &lt;오케이닥터&gt;(‘www.okdr.com’이하 ‘’오케이닥터’) 은(는) 다음의 목적을 위하여
                        개인정보를 처리하고 있으며, 다음의 목적 이외의 용도로는 이용하지 않습니다.
                        고객 가입의사 확인, 고객에 대한 서비스 제공에 따른 본인 식별.인증, 회원자격 유지.
                        관리, 물품 또는 서비스 공급에 따른 금액 결제, 물품 또는 서비스의 공급.배송 등<br /><br />
                        </div>
                        <label className="agree_chk"><input type="checkbox" />개인정보처리방침안내의 내용에 동의합니다.</label>
                      </dd>
                    </dl>                    
                  </div>
                  <div className="tac"><button className="submit">신청하기</button></div>
                </form>

            </div>
          </div>
          <Footer />
        </div>
    )
}

export default Sub1_1_ask;
