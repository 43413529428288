import React from 'react';
import Footer from '../components/Footer';
import Sub_head from '../components/Sub_head';

import '../assets/css/sub.css';

const Login = () => {
    return (
        <div>
            <Sub_head />
            <div className="sub_ctt wrap">
                <div className="container clearfix">

                    <div className="login_wrap">
                        <form className="input_box box">
                            <dl>
                                <dt>아이디</dt>
                                <dd><i class="fas fa-user"></i><input type="text" placeholder="ID" /></dd>
                            </dl>
                            <dl>
                                <dt>비밀번호</dt>
                                <dd><i class="fas fa-unlock-alt"></i><input type="text" placeholder="Password" /></dd>
                            </dl>
                            <button>로그인</button>
                        </form>
                        <dl className="lost_box box">
                            <dt><a href="" target="_blank">회원가입</a></dt>
                            <dd><a href="" target="_blank">아이디/비밀번호 찾기</a></dd>
                        </dl>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Login;