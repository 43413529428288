import React from 'react';

import '../assets/css/sub.css';

const Board_list = () => {

    const notice = [
        {
            num: "14",
            title: "회원가입 시 특별한 혜택을 드립니다. 회원가입 시 특별한 혜택을 드립니다. 회원가입 시 특별한 혜택을 드립니다. 회원가입 시 특별한 혜택을 드립니다. 회원가입 시 특별한 혜택을 드립니다.",
            writer: "관리자",
            datetime: "2021.12.22"
        },
        {
            num: "13",
            title: "회원가입 시 특별한 혜택을 드립니다.",
            writer: "관리자",
            datetime: "2021.12.22"
        },
        {
            num: "12",
            title: "회원가입 시 특별한 혜택을 드립니다.",
            writer: "관리자",
            datetime: "2021.12.22"
        },
        {
            num: "11",
            title: "회원가입 시 특별한 혜택을 드립니다.",
            writer: "관리자",
            datetime: "2021.12.22"
        },
        {
            num: "10",
            title: "회원가입 시 특별한 혜택을 드립니다.",
            writer: "관리자",
            datetime: "2021.12.22"
        },
        {
            num: "9",
            title: "회원가입 시 특별한 혜택을 드립니다.",
            writer: "관리자",
            datetime: "2021.12.22"
        },
        {
            num: "8",
            title: "회원가입 시 특별한 혜택을 드립니다.",
            writer: "관리자",
            datetime: "2021.12.22"
        },
        {
            num: "7",
            title: "회원가입 시 특별한 혜택을 드립니다.",
            writer: "관리자",
            datetime: "2021.12.22"
        },
        {
            num: "6",
            title: "회원가입 시 특별한 혜택을 드립니다.",
            writer: "관리자",
            datetime: "2021.12.22"
        },
    ]

    return (

        <div className="board_list">
            <ul className="table">
                <li className="tr th">
                    <div className="num td">번호</div>
                    <div className="title td">제목</div>
                    <div className="writer td">작성자</div>
                    <div className="date td">작성일</div>
                </li>
                <li className="tr notice_line">
                    <div className="num td"><i class="">공지</i></div>
                    <div className="title td"><a href="">회원가입 시 특별한 혜택을 드립니다.</a></div>
                    <div className="writer td">관리자</div>
                    <div className="date td">2021.12.22</div>
                </li>
                {
                    notice.map((v, i) => {
                        return (
                            <li className="tr">
                                <div className="num td">{v.num}</div>
                                <div className="title td"><a href="">{v.title}</a></div>
                                <div className="writer td">{v.writer}</div>
                                <div className="date td">{v.datetime}</div>
                            </li>
                        );
                    })
                }
            </ul>            
        </div>

    )
}

export default Board_list;