import React from 'react';
import Header from './Header';
import './css/sub_head.css';
import '../assets/css/sub.css';

const Sub_head = () => {
    return (
        <div>
            <Header />
            <div className="sub_head head01 wrap">
                <div className="container clearfix">
                    <h3 className="tit">지원자모집</h3>	
                </div>
            </div>

            <div className="sub_nav wrap">
                <div className="container clearfix">
                    <ul className="nav col-mo-12">
                        <li><a href="/"><i className="fas fa-home"></i></a></li>
                        <li><a href="/sub1">지원자모집</a></li>
                        <li><a href="/sub1_view" className="active">지원자모집 상세보기</a></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Sub_head;