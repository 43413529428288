import React from 'react';

import '../assets/css/sub.css';

const BoardNav = () => {
    return (
        <div>
            <div className="view_nb">
                <dl className="prev box">
                    <dt>
                        <span className="arrow"><i class="fas fa-angle-up"></i>이전글</span>
                        <a href="#;">이전글 타이틀 영역입니다.이전글 타이틀 영역입니다.이전글 타이틀 영역입니다.이전글 타이틀 영역입니다.이전글 타이틀 영역입니다.이전글 타이틀 영역입니다.이전글 타이틀 영역입니다.이전글 타이틀 영역입니다.이전글 타이틀 영역입니다.이전글 타이틀 영역입니다.</a>
                    </dt>
                    <dd>2022.01.06</dd>
                </dl>
                <dl className="next box">
                    <dt>
                        <span className="arrow"><i class="fas fa-angle-down"></i>다음글</span>
                        <a href="#;">다음글 타이틀 영역입니다.</a>
                    </dt>
                    <dd>2022.01.06</dd>
                </dl>
            </div>

        </div>
    )
}

export default BoardNav;