import React from 'react';

import '../assets/css/sub.css';

const BoardComment = () => {
    return (
        <div>

            <div className="comment_write">
                <h3 className="tit"><i class="far fa-comment-dots"></i> 댓글목록</h3>
                <ul className="comment_list">
                    <li>
                        <span className="profile"><i class="fas fa-user-alt"></i> 졸렬캡틴 <span className="date"><i class="far fa-clock"></i> 2022.01.03 14:21</span></span>
                        <span className="cnt">하일 하이드라</span>
                    </li>
                    <li>
                        <span className="profile"><i class="fas fa-user-alt"></i> 철남이 <span className="date"><i class="far fa-clock"></i> 2022.01.03 14:21</span></span>
                        <span className="cnt">아임 아이언맨</span>
                    </li>
                    <li class="view_writer">
                        <span className="profile"><i class="fas fa-user-alt"></i> 글쓴이 <span className="date"><i class="far fa-clock"></i> 2022.01.03 14:21</span></span>
                        <span className="cnt">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            <ul className="cmt_btn">
                                <li><a href="#;">수정</a></li>
                                <li><a href="#;">삭제</a></li>
                            </ul>
                        </span>
                    </li>
                </ul>

                <form>
                    <textarea placeholder="댓글내용을 입력해주세요."></textarea>
                    <ul className="btn_box">
                        <li><a href="#;" className="type_01">댓글등록</a></li>                                
                    </ul>
                </form>

            </div>

        </div>
    )
}

export default BoardComment;