import React from 'react';
import Footer from '../components/Footer';
import Sub_head from '../components/Sub_head';
import Board_list from '../components/Board_list';

import '../assets/css/sub.css';

const Notice = () => {
    return (
        <div>
            <Sub_head />
            <div className="sub_ctt wrap">
                <div className="container clearfix">
                    <Board_list />
                    <ul className="btn_box">
                        <li><a href="/qna_write" className="type_01">문의하기</a></li>
                    </ul>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Notice;