import React from 'react';
import Footer from '../components/Footer';
import Sub_head from '../components/Sub_head';

import '../assets/css/sub.css';

const Join_form = () => {
    return (
        <div>
            <Sub_head />
            <div className="sub_ctt wrap">
                <div className="container clearfix">
                    
                    <form className="join applicant_ask">
                        <div className="ask_form">
                            <p className="ps"><i>*</i> 은 필수입력 항목입니다.</p>
                            <dl>
                                <dt className="important"><span className="inner">아이디</span></dt>
                                <dd className="btn_type"><input type="text" placeholder /><a href="" target="_blank">중복확인</a></dd>
                            </dl>
                            <dl className="half_box">
                                <dt className="important"><span className="inner">비밀번호</span></dt>
                                <dd><input type="password" placeholder /></dd>
                                </dl>
                            <dl className="half_box">
                                <dt className="important"><span className="inner">비밀번호 확인</span></dt>
                                <dd><input type="password" placeholder /></dd>
                            </dl>
                            <dl>
                                <dt className="important"><span className="inner">연락처</span></dt>
                                <dd class="btn_type"><input type="text" placeholder="“-” 제외한 숫자만 입력하세요." /><a href="" target="_blank">본인인증</a></dd>
                            </dl>
                            <dl className="half_box">
                                <dt className="important"><span className="inner">생년월일</span></dt>
                                <dd>
                                    <select className="year">
                                        <option>년도</option>
                                        <option>2022</option>
                                        <option>2021</option>
                                        <option>2020</option>
                                        <option>2019</option>
                                    </select> 
                                    <select className="month">
                                        <option>월</option>
                                        <option>12</option>
                                        <option>11</option>
                                        <option>10</option>
                                        <option>9</option>
                                    </select> 
                                    <select className="day">
                                        <option>일</option>
                                        <option>31</option>
                                        <option>30</option>
                                        <option>29</option>
                                        <option>28</option>
                                    </select>                       
                                </dd>
                            </dl>
                            <dl className="half_box">
                                <dt className="important"><span className="inner">성별</span></dt>
                                <dd class="check_type">
                                    <span>
                                        <input type="checkbox" /> 남자
                                    </span>
                                    <span>
                                        <input type="checkbox" /> 여자
                                    </span>
                                </dd>
                            </dl>
                            <dl>
                                <dt className="important"><span className="inner">이메일</span></dt>
                                <dd className="mail_type">
                                    <input type="text" /><i>@</i>
                                    <input type="text" />
                                    <select>
                                        <option>직접입력</option>
                                        <option>naver.com</option>
                                    </select>
                                </dd>
                            </dl>
                            <dl>
                                <dt className="important"><span className="inner">관심분야</span></dt>
                                <dd className="interest">
                                    <ul>
                                        <li><input type="checkbox" />내과</li>
                                        <li><input type="checkbox" />피부과</li>
                                        <li><input type="checkbox" />안과</li>
                                        <li><input type="checkbox" />정형외과</li>
                                        <li><input type="checkbox" />성형외과</li>
                                        <li><input type="checkbox" />내과</li>
                                        <li><input type="checkbox" />피부과</li>
                                        <li><input type="checkbox" />안과</li>
                                        <li><input type="checkbox" />정형외과</li>
                                        <li><input type="checkbox" />성형외과</li>
                                        <li><input type="checkbox" />내과</li>
                                        <li><input type="checkbox" />피부과</li>
                                        <li><input type="checkbox" />안과</li>
                                        <li><input type="checkbox" />정형외과</li>
                                        <li><input type="checkbox" />성형외과</li>
                                        <li><input type="checkbox" />내과</li>
                                        <li><input type="checkbox" />피부과</li>
                                        <li><input type="checkbox" />안과</li>
                                        <li><input type="checkbox" />정형외과</li>
                                        <li><input type="checkbox" />성형외과</li>
                                    </ul>
                                </dd>
                                <dt className="none">&nbsp;</dt>
                                <dd className="agree_box">
                                    <div className="agree_info">
                                    1. 개인정보의 처리 목적<br /><br />

                                    &lt;오케이닥터&gt;(‘www.okdr.com’이하 ‘오케이닥터’) 은(는) 다음의 목적을 위하여
                                    개인정보를 처리하고 있으며, 다음의 목적 이외의 용도로는 이용하지 않습니다.
                                    고객 가입의사 확인, 고객에 대한 서비스 제공에 따른 본인 식별.인증, 회원자격 유지.
                                    관리, 물품 또는 서비스 공급에 따른 금액 결제, 물품 또는 서비스의 공급.배송 등<br /><br />

                                    2. 개인정보의 처리 목적<br /><br />

                                    &lt;오케이닥터&gt;(‘www.okdr.com’이하 ‘’오케이닥터’) 은(는) 다음의 목적을 위하여
                                    개인정보를 처리하고 있으며, 다음의 목적 이외의 용도로는 이용하지 않습니다.
                                    고객 가입의사 확인, 고객에 대한 서비스 제공에 따른 본인 식별.인증, 회원자격 유지.
                                    관리, 물품 또는 서비스 공급에 따른 금액 결제, 물품 또는 서비스의 공급.배송 등<br /><br />
                                    </div>
                                    <label className="agree_chk"><input type="checkbox" />개인정보처리방침안내의 내용에 동의합니다.</label>
                                </dd>
                            </dl>                    
                        </div>
                        <div className="tac"><button className="submit">가입하기</button></div>
                    </form>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Join_form;