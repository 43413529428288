import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";

import Header from '../components/Header';
import Footer from '../components/Footer';
import Applicant_list from '../components/Applicant_list';


import "swiper/css";
import "swiper/css/pagination"

import '../assets/css/main.css';

import visualImg from '../assets/images/visual01.png';
import tel_icon from '../assets/images/tel_icon.png';

import SwiperCore, { Autoplay,Pagination } from 'swiper';


const Main = () => {
    const notice = [
        {
            title: "공지사항 게시글 타이틀 영역입니다,",
            datetime: "2021.12.17"
        },
        {
            title: "공지사항 게시글 타이틀 영역입니다,",
            datetime: "2021.12.17"
        },
        {
            title: "공지사항 게시글 타이틀 영역입니다,",
            datetime: "2021.12.17"
        },
        {
            title: "공지사항 게시글 타이틀 영역입니다,",
            datetime: "2021.12.17"
        },
        {
            title: "공지사항 게시글 타이틀 영역입니다,",
            datetime: "2021.12.17"
        }
    ]
    const qna = [
        {
            title: "Q&A 게시글 타이틀 영역입니다.",
            datetime: "2021.12.17"
        },
        {
            title: "Q&A 게시글 타이틀 영역입니다.",
            datetime: "2021.12.17"
        },
        {
            title: "Q&A 게시글 타이틀 영역입니다.",
            datetime: "2021.12.17"
        },
        {
            title: "Q&A 게시글 타이틀 영역입니다.",
            datetime: "2021.12.17"
        },
        {
            title: "Q&A 게시글 타이틀 영역입니다.",
            datetime: "2021.12.17"
        }
    ]

    SwiperCore.use([Autoplay,Pagination]);
    
    return ( 
        <>        
        <Header />
        <div className="main_ctt">
            <div className="visual_wrap wrap">
                <Swiper pagination={{"clickable": true}} centeredSlides={true} autoplay={{"delay":3000, "disableOnInteraction": false}} loop={true} className="mySwiper">
                    <SwiperSlide>
                        <div className="visual01 visual">
                            <div className="container clearfix">
                                <div className="txt_box">
                                    <h2 className="tit">제 2형 당뇨병의 환자대상 <br />임상시험 참여자 모집합니다</h2>
                                    <dl className="organ">
                                        <dt>참여기관</dt>
                                        <dd>세브란스병원(서울특별시 서대문구 연세로 50-1) <br />내분비내과 교수 차봉수 외국내 약 30개 종합병원</dd>
                                    </dl>
                                    <a href="/sub1_view" className="cir_more"><span className="inner">자세히보기&nbsp;&nbsp;&nbsp;&gt;</span></a>
                                </div>
                                <div className="img"><img src={visualImg} alt="" /></div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="visual02 visual">
                            <div className="container clearfix">
                                <div className="txt_box">
                                    <h2 className="tit">제 2형 당뇨병의 환자대상 <br />임상시험 참여자 모집합니다</h2>
                                    <dl className="organ">
                                        <dt>참여기관</dt>
                                        <dd>세브란스병원(서울특별시 서대문구 연세로 50-1) <br />내분비내과 교수 차봉수 외국내 약 30개 종합병원</dd>
                                    </dl>
                                    <a href="/sub1_view" className="cir_more"><span className="inner">자세히보기&nbsp;&nbsp;&nbsp;&gt;</span></a>
                                </div>
                                <div className="img"><img src={visualImg} alt="" /></div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="visual03 visual">
                            <div className="container clearfix">
                                <div className="txt_box">
                                    <h2 className="tit">제 2형 당뇨병의 환자대상 <br />임상시험 참여자 모집합니다</h2>
                                    <dl className="organ">
                                        <dt>참여기관</dt>
                                        <dd>세브란스병원(서울특별시 서대문구 연세로 50-1) <br />내분비내과 교수 차봉수 외국내 약 30개 종합병원</dd>
                                    </dl>
                                    <a href="/sub1_view" className="cir_more"><span className="inner">자세히보기&nbsp;&nbsp;&nbsp;&gt;</span></a>
                                </div>
                                <div className="img"><img src={visualImg} alt="" /></div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>

            <div className="section01 wrap">
                <div className="container clearfix">
                    <h2 className="section_tit"><strong>지원자</strong> 모집</h2>
                    <Applicant_list />
                </div>
            </div>

            <div className="board_line wrap">
                <div className="container clearfix">
                    <div className="box col-tb-4 col-mo-6">
                    <div className="inner">
                        <h3 className="board_tit">공지사항<a href="/notice" className="more_icon"></a></h3>
                        <ul className="text_list clearfix">
                        {
                            notice.map((v, i) => {
                                return (
                                    <li><a href="#;"><span className="tit">{v.title}</span><span className="date">{v.datetime}</span></a></li>
                                );
                            })
                        }
                        </ul>
                    </div>
                    </div>
                    <div className="box col-tb-4 col-mo-6">
                    <div className="inner">
                        <h3 className="board_tit">Q&A<a href="/qna" className="more_icon"></a></h3>
                        <ul className="text_list clearfix">
                        {
                            qna.map((v, i) => {
                                return (
                                    <li><a href="#;"><span className="tit">{v.title}</span><span className="date">{v.datetime}</span></a></li>
                                );
                            })
                        }
                        </ul>
                    </div>
                    </div>
                    <div className="cs_center box col-tb-4">
                    <div className="inner">
                        <h3 className="board_tit">고객센터</h3>
                        <div className="num"><a href="tel:042-123-4567"><img src={tel_icon} alt="" />042.123.4567</a></div>
                        <dl className="info">
                        <dt>AM 10 : 00 ~ PM 18 : 00</dt>
                        <dd>토, 일, 공휴일 제외</dd>
                        </dl>
                    </div>
                    </div>				
                </div>
            </div>
        </div>
        <Footer />
    </>
    )
}

export default Main;