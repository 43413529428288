import React from 'react';
import Footer from '../components/Footer';
import Sub_head from '../components/Sub_head';

import '../assets/css/sub.css';

import coming from '../assets/images/sub/coming.png';

const Intro = () => {
    return (
        <div>
            <Sub_head />
            <div className="sub_ctt wrap">
                <div className="container clearfix">
                    <div className="tac"><img src={coming} alt="logo" /></div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Intro;