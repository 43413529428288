import React from 'react';
import Footer from '../components/Footer';
import Sub_head from '../components/Sub_head';
import Applicant_list from '../components/Applicant_list';

const Sub1_1 = () => {
    return (
        <div>
          <Sub_head />
          <div className="sub_ctt wrap">
            <div className="container clearfix">
              
              <Applicant_list />

            </div>
          </div>
          <Footer />
        </div>
    )
}

export default Sub1_1;
