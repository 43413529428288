import React from 'react';
import './css/applicant_list.css';

import applicant_img01 from '../assets/images/section01_img01.png';
import applicant_img02 from '../assets/images/section01_img02.png';
import applicant_img03 from '../assets/images/section01_img03.png';

const Applicant_list = () => {
    return (
        <div>
            <ul className="applicant_list clearfix">
			<li className="col-tb-4 col-mo-6">
					<a href="/sub1_1_view" className="inner">
						<span className="img"><img src={applicant_img01} alt="" /></span>
						<div className="info_box">
							<h5 className="company">㈜큐라티스</h5>
							<h3 className="tit">코로나 19 백신개발을 위한 임상시험 대상자 모집, 코로나 19 백신개발을 위한 임상시험 대상자 모집</h3>
							<dl className="detail">
								<dt>모집기간</dt>
								<dd>2021, 12, 31까지</dd>
								<dt>모집인원</dt>
								<dd>30명</dd>
							</dl>
						</div>
					</a>
				</li>
				<li className="col-tb-4 col-mo-6">
					<a href="/sub1_1_view" className="inner">
						<span className="img"><img src={applicant_img02} alt="" /></span>
						<div className="info_box">
							<h5 className="company">㈜큐라티스</h5>
							<h3 className="tit">코로나 19 백신개발을 위한 임상시험 대상자 모집</h3>
							<dl className="detail">
								<dt>모집기간</dt>
								<dd>2021, 12, 31까지</dd>
								<dt>모집인원</dt>
								<dd>30명</dd>
							</dl>
						</div>
					</a>
				</li>
				<li className="col-tb-4 col-mo-6">
					<a href="/sub1_1_view" className="inner">
						<span className="img"><img src={applicant_img03} alt="" /></span>
						<div className="info_box">
							<h5 className="company">㈜큐라티스</h5>
							<h3 className="tit">코로나 19 백신개발을 위한 임상시험 대상자 모집</h3>
							<dl className="detail">
								<dt>모집기간</dt>
								<dd>2021, 12, 31까지</dd>
								<dt>모집인원</dt>
								<dd>30명</dd>
							</dl>
						</div>
					</a>
				</li>
				<li className="col-tb-4 col-mo-6">
					<a href="/sub1_1_view" className="inner">
						<span className="img"><img src={applicant_img02} alt="" /></span>
						<div className="info_box">
							<h5 className="company">㈜큐라티스</h5>
							<h3 className="tit">코로나 19 백신개발을 위한 임상시험 대상자 모집, 코로나 19 백신개발을 위한 임상시험 대상자 모집</h3>
							<dl className="detail">
								<dt>모집기간</dt>
								<dd>2021, 12, 31까지</dd>
								<dt>모집인원</dt>
								<dd>30명</dd>
							</dl>
						</div>
					</a>
				</li>
				<li className="col-tb-4 col-mo-6">
					<a href="/sub1_1_view" className="inner">
						<span className="img"><img src={applicant_img03} alt="" /></span>
						<div className="info_box">
							<h5 className="company">㈜큐라티스</h5>
							<h3 className="tit">코로나 19 백신개발을 위한 임상시험 대상자 모집</h3>
							<dl className="detail">
								<dt>모집기간</dt>
								<dd>2021, 12, 31까지</dd>
								<dt>모집인원</dt>
								<dd>30명</dd>
							</dl>
						</div>
					</a>
				</li>
				<li className="col-tb-4 col-mo-6">
					<a href="/sub1_1_view" className="inner">
						<span className="img"><img src={applicant_img01} alt="" /></span>
						<div className="info_box">
							<h5 className="company">㈜큐라티스</h5>
							<h3 className="tit">코로나 19 백신개발을 위한 임상시험 대상자 모집</h3>
							<dl className="detail">
								<dt>모집기간</dt>
								<dd>2021, 12, 31까지</dd>
								<dt>모집인원</dt>
								<dd>30명</dd>
							</dl>
						</div>
					</a>
				</li>
				<li className="col-tb-4 col-mo-6">
					<a href="/sub1_1_view" className="inner">
						<span className="img"><img src={applicant_img01} alt="" /></span>
						<div className="info_box">
							<h5 className="company">㈜큐라티스</h5>
							<h3 className="tit">코로나 19 백신개발을 위한 임상시험 대상자 모집, 코로나 19 백신개발을 위한 임상시험 대상자 모집</h3>
							<dl className="detail">
								<dt>모집기간</dt>
								<dd>2021, 12, 31까지</dd>
								<dt>모집인원</dt>
								<dd>30명</dd>
							</dl>
						</div>
					</a>
				</li>
				<li className="col-tb-4 col-mo-6">
					<a href="/sub1_1_view" className="inner">
						<span className="img"><img src={applicant_img02} alt="" /></span>
						<div className="info_box">
							<h5 className="company">㈜큐라티스</h5>
							<h3 className="tit">코로나 19 백신개발을 위한 임상시험 대상자 모집</h3>
							<dl className="detail">
								<dt>모집기간</dt>
								<dd>2021, 12, 31까지</dd>
								<dt>모집인원</dt>
								<dd>30명</dd>
							</dl>
						</div>
					</a>
				</li>
				<li className="col-tb-4 col-mo-6">
					<a href="/sub1_1_view" className="inner">
						<span className="img"><img src={applicant_img03} alt="" /></span>
						<div className="info_box">
							<h5 className="company">㈜큐라티스</h5>
							<h3 className="tit">코로나 19 백신개발을 위한 임상시험 대상자 모집</h3>
							<dl className="detail">
								<dt>모집기간</dt>
								<dd>2021, 12, 31까지</dd>
								<dt>모집인원</dt>
								<dd>30명</dd>
							</dl>
						</div>
					</a>
				</li>
				<li className="col-tb-4 col-mo-6">
					<a href="/sub1_1_view" className="inner">
						<span className="img"><img src={applicant_img02} alt="" /></span>
						<div className="info_box">
							<h5 className="company">㈜큐라티스</h5>
							<h3 className="tit">코로나 19 백신개발을 위한 임상시험 대상자 모집, 코로나 19 백신개발을 위한 임상시험 대상자 모집</h3>
							<dl className="detail">
								<dt>모집기간</dt>
								<dd>2021, 12, 31까지</dd>
								<dt>모집인원</dt>
								<dd>30명</dd>
							</dl>
						</div>
					</a>
				</li>
				<li className="col-tb-4 col-mo-6">
					<a href="/sub1_1_view" className="inner">
						<span className="img"><img src={applicant_img03} alt="" /></span>
						<div className="info_box">
							<h5 className="company">㈜큐라티스</h5>
							<h3 className="tit">코로나 19 백신개발을 위한 임상시험 대상자 모집</h3>
							<dl className="detail">
								<dt>모집기간</dt>
								<dd>2021, 12, 31까지</dd>
								<dt>모집인원</dt>
								<dd>30명</dd>
							</dl>
						</div>
					</a>
				</li>
				<li className="col-tb-4 col-mo-6">
					<a href="/sub1_1_view" className="inner">
						<span className="img"><img src={applicant_img01} alt="" /></span>
						<div className="info_box">
							<h5 className="company">㈜큐라티스</h5>
							<h3 className="tit">코로나 19 백신개발을 위한 임상시험 대상자 모집</h3>
							<dl className="detail">
								<dt>모집기간</dt>
								<dd>2021, 12, 31까지</dd>
								<dt>모집인원</dt>
								<dd>30명</dd>
							</dl>
						</div>
					</a>
				</li>
				<li className="col-tb-4 col-mo-6">
					<a href="/sub1_1_view" className="inner">
						<span className="img"><img src={applicant_img01} alt="" /></span>
						<div className="info_box">
							<h5 className="company">㈜큐라티스</h5>
							<h3 className="tit">코로나 19 백신개발을 위한 임상시험 대상자 모집, 코로나 19 백신개발을 위한 임상시험 대상자 모집</h3>
							<dl className="detail">
								<dt>모집기간</dt>
								<dd>2021, 12, 31까지</dd>
								<dt>모집인원</dt>
								<dd>30명</dd>
							</dl>
						</div>
					</a>
				</li>
				<li className="col-tb-4 col-mo-6">
					<a href="/sub1_1_view" className="inner">
						<span className="img"><img src={applicant_img02} alt="" /></span>
						<div className="info_box">
							<h5 className="company">㈜큐라티스</h5>
							<h3 className="tit">코로나 19 백신개발을 위한 임상시험 대상자 모집</h3>
							<dl className="detail">
								<dt>모집기간</dt>
								<dd>2021, 12, 31까지</dd>
								<dt>모집인원</dt>
								<dd>30명</dd>
							</dl>
						</div>
					</a>
				</li>
				<li className="col-tb-4 col-mo-6">
					<a href="/sub1_1_view" className="inner">
						<span className="img"><img src={applicant_img03} alt="" /></span>
						<div className="info_box">
							<h5 className="company">㈜큐라티스</h5>
							<h3 className="tit">코로나 19 백신개발을 위한 임상시험 대상자 모집</h3>
							<dl className="detail">
								<dt>모집기간</dt>
								<dd>2021, 12, 31까지</dd>
								<dt>모집인원</dt>
								<dd>30명</dd>
							</dl>
						</div>
					</a>
				</li>
				<li className="col-tb-4 col-mo-6">
					<a href="/sub1_1_view" className="inner">
						<span className="img"><img src={applicant_img02} alt="" /></span>
						<div className="info_box">
							<h5 className="company">㈜큐라티스</h5>
							<h3 className="tit">코로나 19 백신개발을 위한 임상시험 대상자 모집, 코로나 19 백신개발을 위한 임상시험 대상자 모집</h3>
							<dl className="detail">
								<dt>모집기간</dt>
								<dd>2021, 12, 31까지</dd>
								<dt>모집인원</dt>
								<dd>30명</dd>
							</dl>
						</div>
					</a>
				</li>
				<li className="col-tb-4 col-mo-6">
					<a href="/sub1_1_view" className="inner">
						<span className="img"><img src={applicant_img03} alt="" /></span>
						<div className="info_box">
							<h5 className="company">㈜큐라티스</h5>
							<h3 className="tit">코로나 19 백신개발을 위한 임상시험 대상자 모집</h3>
							<dl className="detail">
								<dt>모집기간</dt>
								<dd>2021, 12, 31까지</dd>
								<dt>모집인원</dt>
								<dd>30명</dd>
							</dl>
						</div>
					</a>
				</li>
				<li className="col-tb-4 col-mo-6">
					<a href="/sub1_1_view" className="inner">
						<span className="img"><img src={applicant_img01} alt="" /></span>
						<div className="info_box">
							<h5 className="company">㈜큐라티스</h5>
							<h3 className="tit">코로나 19 백신개발을 위한 임상시험 대상자 모집</h3>
							<dl className="detail">
								<dt>모집기간</dt>
								<dd>2021, 12, 31까지</dd>
								<dt>모집인원</dt>
								<dd>30명</dd>
							</dl>
						</div>
					</a>
				</li>
			</ul>
			<div className="tac">
				<a href="#;" className="cir_more"><span className="inner">더보기&nbsp;&nbsp;&nbsp;+</span></a>
			</div>
        </div>
    )
}

export default Applicant_list;