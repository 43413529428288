import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";

import './css/footer.css';
import "swiper/css";
import "swiper/css/navigation"


import logo from '../assets/images/m_logo.png';

import SwiperCore, { Autoplay,Navigation } from 'swiper';
  

const Footer = () => {
    SwiperCore.use([Autoplay,Navigation]);

    return (
        <div id="footer">
            <div className="ft_slide">
                <div className="container clearfix">
                    <h3 className="slide_tit">PARTNERS</h3>
                    <div className="fts slide">
                        <Swiper navigation={true} slidesPerView={2} spaceBetween={30} autoplay={{"delay":3000, "disableOnInteraction": false}} loop={true} breakpoints={{
                            "768": {
                                "slidesPerView": 3,
                                "spaceBetween": 40
                            },
                            "1200": {
                                "slidesPerView": 4,
                                "spaceBetween": 50
                            }
                        }} className="mySwiper">
                            <SwiperSlide><a href="#;" target="_blank"><img src={logo} alt="logo" /></a></SwiperSlide>
                            <SwiperSlide><a href="#;" target="_blank"><img src={logo} alt="logo" /></a></SwiperSlide>
                            <SwiperSlide><a href="#;" target="_blank"><img src={logo} alt="logo" /></a></SwiperSlide>
                            <SwiperSlide><a href="#;" target="_blank"><img src={logo} alt="logo" /></a></SwiperSlide>
                            <SwiperSlide><a href="#;" target="_blank"><img src={logo} alt="logo" /></a></SwiperSlide>
                            <SwiperSlide><a href="#;" target="_blank"><img src={logo} alt="logo" /></a></SwiperSlide>
                            <SwiperSlide><a href="#;" target="_blank"><img src={logo} alt="logo" /></a></SwiperSlide>
                            <SwiperSlide><a href="#;" target="_blank"><img src={logo} alt="logo" /></a></SwiperSlide>
                        </Swiper>
                    </div>
                    <div className="arrow_box">
                        <a href="#;" className="pause arrow"></a>
                    </div>
                </div>
            </div>

            <div className="fnb_wrap wrap">
                <div className="container claerfix">
                    <ul className="fnb">				
                        <li><a href="/intro">오케이닥터소개</a></li>
                        <li><a href="/provision">이용약관</a></li>
                        <li><a href="/privacy">개인정보취급방침</a></li>
                        <li><a href="/hospital_ask">임상시험등록</a></li>
                    </ul>
                </div>
            </div>

            <footer className="footer wrap">
                <div className="container clearfix">
                    <h1 className="f_logo"><a href="/"><img src={logo} alt="logo" /></a></h1>
                    <div className="info_box">
                        <ul className="info">
                            <li><span>상호 : ㈜스페이드</span><span>대표 : 고의</span><span>주소 : 대전광역시 중구 보문로 000</span></li>
                            <li><span>사업자등록번호 : 000-00-0000</span><span>대표전화 : 042-123-1234</span><span>팩스 : 042-321-4321</span></li>					
                        </ul>
                        <p className="copyright">COPYRIGHT © 2021 (주)스페이드 ALL RIGHTS RESEVED</p>
                    </div>
                </div>
            </footer>
        </div>
    )
}
export default Footer;