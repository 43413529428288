import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/m_logo.png';

import './css/header.css';
import GnbToggle from './Gnb_toggle';



const Header = () => {
    return (
        <header className="header wrap">           
            <div className="tnb">
                <div className="container clearfix">
                    <ul className="left">
                        <li><a href="/">메인</a></li>
                        <li><a href="">즐겨찾기 추가</a></li>
                    </ul>
                    <ul className="right">
                        <li><a href="/login">로그인</a></li>
                        <li><a href="/join_form">회원가입</a></li>
                        <li><a href="/Lost">아이디/비밀번호 찾기</a></li>
                    </ul>           
                </div>
            </div>
            <div className="gnb_line">
                <div className="container clearfix">
                    <h1 className="m_logo col-mo-2"><a href="/"><img src={logo} alt="logo" /></a></h1>
                    <nav className="gnb col-mo-8">
                        <ul className="de1_ul">
                            <li className="de1_li"><a href="/sub1" className="de1_a">지원자 모집</a></li>
                            <li className="de1_li"><a href="/notice" className="de1_a">공지사항</a></li>
                            <li className="de1_li"><a href="/qna" className="de1_a">Q&amp;A</a></li>
                        </ul>
                    </nav>
                    <div className="register_btn col-mo-2"><a href="/hospital_ask"> 임상시험등록</a></div>
                    <GnbToggle />
                </div>
            </div>            
        </header>
    )
}

export default Header;
