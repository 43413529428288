import React from 'react';

import '../assets/css/sub.css';
import tel_icon from '../assets/images/tel_icon.png';

const Gnb_toggle = () => {
    return (
        <div className="m_gnb">            
            <a href="#;" className="toggle">
                <span className="bar1"></span>
                <span className="bar2"></span>
                <span className="bar3"></span>
            </a>
            <div className="inner">
                <a href="tel:042-123-4567" className="cs_tel"><img src={tel_icon} alt="" />042.123.4567</a>
                <ul className="m_tnb clearfix">
                    <li><a href="/login"><i className="fas fa-unlock-alt"></i>로그인</a></li>
                    <li><a href="/join_form"><i className="fas fa-user-plus"></i>회원가입</a></li>
                    <li><a href="/Lost"><i className="fas fa-user-check"></i>ID/PW 찾기</a></li>
                </ul>
                <ul className="de1_ul">
                    <li className="de1_li"><a href="/sub1" className="de1_a">지원자 모집</a></li>
                    <li className="de1_li"><a href="/notice" className="de1_a">공지사항</a></li>
                    <li className="de1_li"><a href="/qna" className="de1_a">Q&A</a></li>
                </ul>
                <div className="test_submit"><a href="/hospital_ask">임상시험등록</a></div>
            </div>
            
        </div>
    )
}

export default Gnb_toggle;